import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";

import App from "./App";
import { ThemeProvider } from "./contexts/theme";
import "./index.scss";

import ReactGA from "react-ga";
ReactGA.initialize("UA-55020319-2");
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter basename="/">
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </HashRouter>
);
