import React, { useState } from "react";
import { Header, Footer, Login, Project } from "../../components";
import classes from "./home.module.scss";
import allProjectsData from "../../data/allProjects.json";

export const HomePage = () => {
  return (
    <div>
      <Header />
      <div className={classes.container} id="work">
        <div className={classes.content}>
          {allProjectsData.map(
            (project, index) =>
              project.isVisible && (
                <Project
                  company={project.company}
                  title={project.title}
                  path={project.path}
                  img={project.img}
                  alt={project.alt}
                  key={index}
                />
              )
          )}
        </div>
      </div>
      )
      <Footer />
    </div>
  );
};

export default HomePage;
