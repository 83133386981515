import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../contexts/theme";
import { Link, NavLink } from "react-router-dom";
import { TbSun as IconSun, TbMoonStars as IconMoon } from "react-icons/tb";
import useScrollListener from "../../hooks/useScrollListener";
import { HiMenu as IconMenu } from "react-icons/hi";
import { IoClose as IconClose } from "react-icons/io5";
import classes from "./navbar.module.scss";

const Navbar = ({ token }) => {
  const [{ theme, isDark }, toggleTheme] = useContext(ThemeContext);
  const [navClass, setNavClass] = useState();
  const scroll = useScrollListener();
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    if (scroll.y > 150 && scroll.y - scroll.lastY > 0) {
      setNavClass(classes.navbarHidden);
    } else if (scroll.y < 150) {
      setNavClass(classes.navbarNeutral);
    } else {
      setNavClass(classes.navbarActive);
    }
  }, [scroll.y, scroll.lastY]);

  return (
    <div className={classes.navContainer}>
      {toggleMenu ? (
        <div
          className={classes.menuContainer}
          onClick={() => {
            setToggleMenu(!toggleMenu);
          }}
        >
          <div className={classes.header}>
            <p className={classes.caption}>kristin au</p>
            <button className={classes.close}>
              close <IconClose className={classes.icon} />
            </button>
          </div>

          <ul className={classes.menuLinks}>
            <li>
              <NavLink to="/">work</NavLink>
            </li>
            <li>
              <NavLink to="/about">about</NavLink>
            </li>
          </ul>
        </div>
      ) : (
        <nav className={navClass}>
          <div className={classes.logoContainer}>
            <Link className={classes.logo} to="/">
              k.au
            </Link>
          </div>

          <div className={classes.linksContainer}>
            <div className={classes.links}>
              <div className={classes.navLinks}>
                <NavLink to="/">work</NavLink>
                <NavLink to="/about">about</NavLink>
              </div>

              <button onClick={toggleTheme}>
                {isDark ? (
                  <IconSun className={classes.icon} />
                ) : (
                  <IconMoon className={classes.icon} />
                )}
              </button>

              <button
                className={classes.menu}
                onClick={() => {
                  setToggleMenu(!toggleMenu);
                }}
              >
                <IconMenu className={classes.icon} />
              </button>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};

export default Navbar;
